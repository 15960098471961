import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css } from '@emotion/css';
import { calculateFontSize, useTheme2 } from '@grafana/ui';

interface Props extends PanelProps<SimpleOptions> {}

interface Result {
  backgroundColor: string
  textColor: string
  title: string
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const theme = useTheme2();

  const colors = {
    background: {
      green: "rgb(115, 191, 105)",
      yellow: "rgb(250, 204, 21)",
      red: "rgb(242, 73, 92)"
    }
  };

  const partialResult: Partial<Result> | undefined = scopedEval({
    colors,
    data
  }, options.script);

  const result: Result = {
    backgroundColor: partialResult?.backgroundColor || "green",
    textColor: partialResult?.textColor || "white",
    title: partialResult?.title || "Title"
  };

  const fontSize = calculateFontSize(result.title, width - 10, height, 1.57143);

  return (
    <div
      className={
        css`
          position: relative;
          font-weight: ${theme.typography.fontWeightBold};
          width: ${width}px;
          height: ${height}px;
          background-color: ${result.backgroundColor};
        `
      }
    >
      <div className={
        css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: ${result.textColor};
          font-size: ${fontSize}px;
        `
      }>
        {result.title}
      </div>
    </div>
  );
};

const scopedEval = (scope: any, script: string) => Function(`"use strict"; ${script}`).bind(scope)();
