import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';
import { MonacoEditor } from "./components/Editor";

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel)
    .setNoPadding()
    .setPanelOptions((builder) => {
        return builder
            .addCustomEditor({
                id: "script",
                path: "script",
                name: "Script",
                editor: MonacoEditor
            })
    });
